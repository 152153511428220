@import "../../../styles/gifts/sass/basics";

@keyframes :local(scaleImage) {
  0%, 66% {
    padding-top: calc(100vh - 80px);
    margin: 0;
  }
  100% {}
}

@keyframes :local(transformContentLg) {
  0%, 66% {
    left: 30px;
  }
  100% {
    left: 70px;
  }
}

@keyframes :local(transformContentXXl) {
  0%, 66% {
    left: calc(-1 * (50vw - 770px));
  }
  100% {}
}

.bb-hero-with-two-buttons {
  width: 100%;
  margin: 0 auto;
  position: relative;

  .bb-hero-with-two-buttons {
    &__image-container {
      position: relative;

      img {
        width: 100%;
        height: auto;
        aspect-ratio: 375/250;
      }
    }

    &__content {
      background: $white;
      padding: 24px 0 32px;
    }

    &__heading {
      font-size: 28px;
      line-height: $line-height-normal;
      color: $turquoise-1000;
      font-weight: bold;
      text-transform: uppercase;
    }

    &__subheading {
      margin-top: 24px;
      font-size: 14px;
      line-height: $line-height-large;
      color: $turquoise-1200;
    }

    &__buttons {
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }
  }

  :global(.container) {
    position: relative;
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    .bb-hero-with-two-buttons {
      &__content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &__image-container {
        padding-top: 560px;
        min-height: 600px;

        img {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          object-fit: cover;
          object-position: 50% top;
        }
      }

      &__content {
        position: absolute;
        width: 354px;
        bottom: 35px;
        left: calc(-1 * (50vw - 380px));
        padding: 24px 30px 32px 32px;
        clip-path: polygon(0 0, 100% 0, 90% 94%, 0% 100%);
      }

      &__heading {
        max-width: 278px;
      }

      &__subheading {
        max-width: 278px;
      }

      &__buttons {
        margin-top: 32px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .bb-hero-with-two-buttons {
      &__content {
        left: 70px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .bb-hero-with-two-buttons {
      &__image-container {
        padding-top: 640px;
        min-height: 640px;
        margin: 0 40px 40px;

        &:local {
          animation-iteration-count: 1;
          animation: scaleImage 2s;
        }
      }

      &__content {
        width: 540px;
        bottom: auto;
        top: 58px;
        left: 70px;
        padding: 24px 20px 44px 40px;

        &:local {
          animation-iteration-count: 1;
          animation: transformContentLg 2s;
        }
      }

      &__heading {
        font-size: 40px;
        max-width: 440px;
      }

      &__subheading {
        font-size: 18px;
        display: block;
        max-width: 440px;
        margin-top: 24px;
      }

      &__buttons {
        [data-component^="button"] {
          height: 40px;
          font-size: 16px;
          font-weight: 600;
          padding: 8px 28px;
        }
      }
    }
  }

  @media all and (min-width: 1440px) {
    .bb-hero-with-two-buttons {
      &__heading,
      &__subheading {
        max-width: 505px;
      }

      &__content {
        padding: 24px 20px 64px 40px;
        width: 620px;
        top: 90px;
        left: 70px;

        &:local {
          animation-iteration-count: 1;
          animation: transformContentXXl 2s;
        }
      }

      &__buttons {
        margin-bottom: 0;
        flex-direction: row;

        [data-component^="button"] {
          height: 48px;
          font-size: 18px;
        }
      }
    }
  }

  @media all and (min-width: 1920px) {
    .bb-hero-with-two-buttons {
      &__image-container {
        padding-top: calc(100vw / 2.3);
        min-height: 720px;
        margin: 0 64px 64px;
      }

      &__content {
        left: 30px;
      }
    }
  }
}
